import axios from "axios";
import store from "../store";

// Request interceptor
axios.interceptors.request.use((request) => {
  const token = store.getters["auth/getToken"];

  request.baseURL = "/api";
  request.headers.common["Content-Type"] = "application/json";

  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
  }

  const locale = store.getters["lang/getLang"];

  if (locale) {
    request.headers.common["Accept-Language"] = locale;
  }

  return request;
});
