<template>
  <div>
    <router-view></router-view>
    <idle-modal
      :active-idle-modal.sync="activeIdleModal"
      @on-success="clearInterval(this.timerId)"
      @on-close="activeIdleModal = false"
    ></idle-modal>
  </div>
</template>

<script>
import idleHelper from "@/mixins/idleHelper.js";
import helpers from "@/mixins/helpers";
import eventListeners from "@/mixins/eventListeners.js";
import notification from "@/mixins/notification";
import pusher from "@/mixins/pusher";

export default {
  mixins: [idleHelper, helpers, notification, pusher, eventListeners],

  components: {
    IdleModal: () => import("@/components/Modal/IdleModal.vue"),
  },
};
</script>
